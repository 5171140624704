<div class="main">
    <div class="background-image">
        <img src="/assets/Website-Home-Page-edited-1024x603.png" width="100%"/>
        <div class="image-overlay">
            <h2>Welcome to Omni Method</h2>
            <a href="https://apps.apple.com/us/app/your-app-name/id123456789" target="_blank" class="material-button">
                Download on the App Store
            </a>
        </div>
    </div>
    <div class="grid-container">
       <mat-grid-list [cols]="breakPoint" rowHeight="fit" gutterSize="2em"  (window:resize)="handleSize($event)">
        <mat-grid-tile>
            <h4>
                Omni Method is designed to help you achieve your highest potential by
            providing detailed information about your physical abilities
            </h4>
        </mat-grid-tile>
        
        <mat-grid-tile>
            <h4>
                By identifying your strengths and weaknesses, you can create a personalized
            plan to improve your overall fitness level
            </h4></mat-grid-tile>
        
        <mat-grid-tile>
            <h4>
                Omni Method provides a comprehensive look at your abilities and offers tips
            to help you optimize your performance
            </h4></mat-grid-tile>
    </mat-grid-list>
    </div>
    

 
    <div class="container">
        <h1>The Omni Method Philosophy</h1>
              <p>
                  At Omni Method, we believe that every individual has the potential to excel in a wide range of physical pursuits. Omni Method focuses on developing athletes in six core components:
              </p>
      
              <!-- <mat-tab-group fitInkBarToContent>
                <mat-tab label="Muscular Strength">
                    text
                </mat-tab>
                <mat-tab label="Muscular Endurance">
                    <p>text</p>
                </mat-tab>
                <mat-tab label="Power and Speed">
                    <p>text</p>
                </mat-tab>
                <mat-tab label="Flexibility" disabled>
                    <p>text</p>
                </mat-tab>
                <mat-tab label="Cardiorespiratory" disabled>
                    <p>text</p>
                </mat-tab>
                <mat-tab label="Neuromotor Skills" disabled>
                    <p>text</p>
                </mat-tab>
              </mat-tab-group>
       -->
              <p>
                  With Omni Method you're mastering a unique sport that encompasses all these components. Join us on this extraordinary journey to become a true hybrid athlete.
              </p>
    </div>
</div>
