<mat-toolbar #navbar color="primary" class="navbar">
    <div routerLink="/"><img src="/assets/Horizontal-Logo-1024x644.png" height="60px"/></div>
    <div>
        <button mat-button  routerLink="about">About</button>
        <button mat-button routerLink="privacy">Privacy Policy</button>
        <button mat-button routerLink="terms">Terms and Service</button>
    </div>
</mat-toolbar>

<router-outlet></router-outlet>

<!-- <mat-menu #appMenu="matMenu">
    <button mat-menu-item [mat-menu-trigger-for]="subMenu">FrontEnd</button>
    <button mat-menu-item>BackEnd</button>
</mat-menu>

<button mat-flat-button [mat-menu-trigger-for]="appMenu">Menu</button>

<mat-menu #subMenu="matMenu">
    <button mat-menu-item>Angular</button>
    <button mat-menu-item>React</button>
    <button mat-menu-item>Vue</button>
</mat-menu> -->

<!-- <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" [(opened)]="opened">Sidenav</mat-sidenav>
    <mat-sidenav-content>Main
        <button (click)="opened=!opened">Menu</button>
        <button (click)="sidenav.open()">Open</button>
        <button (click)="sidenav.close()">Close</button>
        <button (click)="sidenav.toggle()">Toggle</button>
    </mat-sidenav-content>
</mat-sidenav-container> -->

<!-- <mat-slide-toggle>Toggle me!</mat-slide-toggle> -->

