<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>About Omni Method</title>
</head>
<body>
    <section class="section1">
        <header>
            <h1>About Omni Method</h1>
        </header>
        <!-- <div class="content">
            <p>If we add a slogan</p>
        </div> -->
    </section>
    
    <section class="section2">
        <div class="content">
            <h2>Our Journey</h2>
            <p> The story of Omni Method began with a vision to revolutionize the world of fitness and athletic performance. 
                Fueled by a passion for holistic athleticism, we set out on a journey to create a unique and comprehensive approach to training that would empower individuals to become true hybrid athletes.</p>
        </div>
    </section>
    
    <section class="section3">
        <div class="content">
            <h2>The Birth of Omni Method</h2>
            <p>Drawing inspiration from various sports and disciplines, we sought to combine the best elements into a single, cohesive system. The result is Omni Method – a fully comprehensive hybrid athlete sport designed to push boundaries and redefine athletic achievement.</p>
        </div>

    <section class="section4">
        <div class="content">
            <h2>Embracing Community</h2>
            <p>Our community is brought together through the Omni Method App, where athletes from around the world can connect, motivate, and inspire each other to achieve their goals.</p>
        </div>
    </section>

    <section class="section5">
        <div class="content">
            <h2>Our Mission</h2>
            <p>Our mission at Omni Method is to empower individuals to unlock their full athletic potential. We believe that athleticism is not limited by specialization but is the result of a holistic approach to training. We are dedicated to providing you with the tools, guidance, and a virtual community to become a true hybrid athlete.</p>
        </div>
    </section>

    <section class="section6">
        <div class="content">
            <h2>Join the Omni Method</h2>
            <p>Whether you're an experienced athlete looking to take your skills to the next level or someone new to the world of fitness and sports, Omni Method welcomes you. </p>
            <p>Thank you for exploring our website, and we look forward to welcoming you into the Omni Method community through our app. Together, we will redefine athleticism and inspire each other to reach new heights.</p>
        </div>
    </section>


        <footer>
            <p>&copy; 2023 Omni Method LLC</p>
        </footer>
    </section>
</body>

